<template>
  <div class="post-success-page">
    <div class="success-icon">
      <van-icon name="success" />
    </div>
    <h1 class="title">发布成功！</h1>
    <p class="subtitle">您的信息已经成功发布！</p>

    <div class="countdown-wrapper">
      <div class="countdown-bar" :style="{ width: `${(countdown / 5) * 100}%` }"></div>
      <p class="countdown">{{ countdownMessage }}</p>
    </div>

    <div class="options">
      <van-button type="primary" icon="eye-o" @click="viewPost">查看帖子</van-button>
      <van-button type="info" icon="share-o" @click="sharePost">分享帖子</van-button>
      <van-button type="default" icon="home-o" @click="goFirst">返回主页</van-button>
      <van-button type="default" icon="plus" @click="newPost">再发一个</van-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  props: {
    postId: {
      type: Number,
      required: true
    },
    basePath: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      countdown: 5,
      countdownTimer: null
    };
  },
  computed: {
    countdownMessage() {
      return `${this.countdown} 秒后自动跳转到新帖`;
    }
  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    this.clearCountdown();
  },
  methods: {
    startCountdown() {
      this.countdownTimer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.clearCountdown();

          router.push({
            name: 'PostWrapperComponent',
            params: {
              basePath: this.basePath,
              postId: this.postId,
            },
            query: {
              mode: 'view',
            },
          });

        }
      }, 1000);
    },
    clearCountdown() {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
      }
    },
    viewPost() {
      this.clearCountdown();
      router.push({
        name: 'PostWrapperComponent',
        params: {
          basePath: this.basePath,
          postId: this.postId,
        },
        query: {
          mode: 'view',
        },
      });
    },
    sharePost() {
      this.clearCountdown();
      const postUrl = `http://yourapp.com/post/${this.postId}`;
      if (navigator.share) {
        navigator.share({
          title: '招聘信息',
          url: postUrl
        }).catch(error => console.log('Error sharing', error));
      } else {
        alert(`复制此链接分享: ${postUrl}`);
      }
    },
    newPost() {
      this.clearCountdown();
      this.$router.push('/selectPublishItem');  // 更新这里
    },
    goFirst() {
      this.clearCountdown();
      this.$router.push('/first');  // 更新这里
    }
  }
};
</script>

<style scoped>
.post-success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
}

.success-icon {
  font-size: 80px;
  color: #07c160;
  margin-bottom: 20px;
  animation: bounce 1s ease-in-out;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

.title {
  font-size: 28px;
  color: #323233;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #969799;
  margin-bottom: 20px;
}

.countdown-wrapper {
  width: 100%;
  max-width: 300px;
  background-color: #e8e8e8;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}

.countdown-bar {
  height: 4px;
  background-color: #07c160;
  transition: width 1s linear;
}

.countdown {
  font-size: 14px;
  color: #323233;
  text-align: center;
  margin-top: 10px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 300px;
}

.options .van-button {
  width: 100%;
  height: 44px;
  font-size: 16px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.options .van-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.options .van-button--primary {
  background-color: #07c160;
  border-color: #07c160;
}

.options .van-button--info {
  background-color: #1989fa;
  border-color: #1989fa;
}

.options .van-button--default {
  color: #323233;
  background-color: #fff;
  border-color: #dcdee0;
}
</style>